import { createTheme } from "@mui/material";

const drawerTheme = createTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 30,
  },
});

export default drawerTheme;
