const apiKey = "593054fa7e390be7af02ea28725dbc20";
const baseUrl = "https://api.flickr.com/services/rest/";

const fetchPhotos = async (albumId, setPhotos) => {
  try {
    const response = await fetch(
      `${baseUrl}?method=flickr.photosets.getPhotos&api_key=${apiKey}&photoset_id=${albumId}&format=json&nojsoncallback=1`
    );
    const data = await response.json();
    console.log(data);
    const urls = data.photoset.photo.map((photo) => {
      return `https://live.staticflickr.com/${photo.server}/${photo.id}_${photo.secret}_b.jpg`;
    });
    setPhotos(urls);
  } catch (e) {
    console.log(e.message);
  }
};

export default fetchPhotos;
