import { SocialIcon } from "react-social-icons";
import { Stack } from "@mui/material";

export default function Social(props) {
  const backgroundColour = "black";
  const foregroundColour = "white";
  const size = 40;
  return (
    <Stack direction="row" sx={{ mb: 1 }} spacing={2}>
      <SocialIcon
        url="https://www.instagram.com/hannah__grifffith/"
        bgColor={backgroundColour}
        fgColor={foregroundColour}
        target="_blank"
        style={{ height: size, width: size }}
      />
      <SocialIcon
        url="https://www.tiktok.com/@haannahg"
        bgColor={backgroundColour}
        fgColor={foregroundColour}
        target="_blank"
        style={{ height: size, width: size }}
      />
      <SocialIcon
        url="https://www.youtube.com/@HanhannASMR"
        bgColor={backgroundColour}
        fgColor={foregroundColour}
        target="_blank"
        style={{ height: size, width: size }}
      />
    </Stack>
  );
}
