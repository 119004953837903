import React, { useState, useEffect } from "react";
import fetchPhotos from "../Networking";
import { ImageList, ImageListItem } from "@mui/material";

export default function ImagePage(props) {
  const [photos, setPhotos] = useState([]);
  const { albumId } = props;

  useEffect(() => {
    fetchPhotos(albumId, setPhotos);
  }, [albumId]);

  return (
    <ImageList cols={3} sx={{ m: 10 }} gap={50}>
      {photos.map((url) => (
        <ImageListItem key={url}>
          <img src={url} alt="From Flickr album" />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
