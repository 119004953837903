import { Stack } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerNavigation from "./DrawerNavigation";
import { Link } from "react-router-dom";

export default function NavBar(props) {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const toggleDrawerNavigation = () => setIsDrawerOpen(!isDrawerOpen);
  return (
    <div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <MenuIcon onClick={toggleDrawerNavigation} className="hamburger" />
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          <h1>bonyu</h1>
        </Link>
        <Link to="/About" style={{ textDecoration: "none", color: "black" }}>
          <h4 className="screen-edge-right">About</h4>
        </Link>
      </Stack>
      <DrawerNavigation
        isDrawerOpen={isDrawerOpen}
        toggleDrawerNavigation={toggleDrawerNavigation}
      />
    </div>
  );
}
