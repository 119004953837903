import { Link } from "react-router-dom";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";

export default function DrawerElement(props) {
  const { text } = props;
  return (
    <Link to={`/${text}`} style={{ color: "black", textDecoration: "none" }}>
      <ListItem key={text} disablePadding>
        <ListItemButton>
          <ListItemText primary={text} />
        </ListItemButton>
      </ListItem>
    </Link>
  );
}
