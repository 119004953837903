import * as React from "react";
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ThemeProvider,
} from "@mui/material";
import Social from "./Social";
import CloseIcon from "@mui/icons-material/Close";
import DrawerElement from "./DrawerElement";
import drawerTheme from "../Themes";

export default function DrawerNavigation(props) {
  const { isDrawerOpen, toggleDrawerNavigation } = props;
  const pages = ["Collage", "Ceramics", "About"];

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawerNavigation}
        >
          <Box
            sx={{ width: 300 }}
            role="presentation"
            onClick={toggleDrawerNavigation}
            onKeyDown={toggleDrawerNavigation}
          >
            <List>
              <ListItemButton sx={{ mb: 3 }}>
                <CloseIcon sx={{ mr: 1 }} />
                <ListItemText primary="Close" />
              </ListItemButton>
              <ThemeProvider theme={drawerTheme}>
                {pages.map((text) => (
                  <DrawerElement text={text} key={text} />
                ))}
              </ThemeProvider>
            </List>
            <List style={{ position: "absolute", bottom: "0" }}>
              <div className="drawer-left">
                <Social />
              </div>
            </List>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
