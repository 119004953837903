import { Route, Routes } from "react-router-dom";

import LandingPage from "./Pages/LandingPage";
import AboutPage from "./Pages/AboutPage";
import ImagePage from "./Pages/ImagePage";
import NavBar from "./Components/NavBar";
import "./App.css";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/About" element={<AboutPage />} />
        <Route
          path="/Collage"
          element={<ImagePage albumId="72177720306119208" />}
        />
        <Route
          path="/Ceramics"
          element={<ImagePage albumId="72177720306115209" />}
        />
      </Routes>
    </div>
  );
}

export default App;
