import { Paper } from "@mui/material";
import Social from "../Components/Social";

export default function AboutPage(props) {
  return (
    <div className="screen-edge">
      <h1>About</h1>
      <p>Some text here about the work</p>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <div className="social-footer">
          <Social />
        </div>
      </Paper>
    </div>
  );
}
