import React, { useState, useEffect } from "react";
import fetchPhotos from "../Networking";

export default function LandingPage(props) {
  const [photos, setPhotos] = useState([]);
  const { albumId } = props;

  useEffect(() => {
    fetchPhotos("72177720306102365", setPhotos);
  }, [albumId]);

  return (
    <div>
      {photos.map((url) => (
        <img key={url} src={url} alt="From Flickr album" />
      ))}
    </div>
  );
}
